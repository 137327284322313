import React from 'react'
import './Slider.css'

function Slider() {
  return (
    <>   
    
    <div class="container-fluid p-0" id='home'>
    <div class="banner" id='sliderhome'>
     <video className="video" autoPlay loop  muted>
        <source src="/images/Comp.mp4" type="video/mp4" />
      </video>
      <div className="row">
        <div className="texts" id='textss' >
          <div>
          <img src="/images/slogan.png" alt="" /> <br />
        <a href="/contactus">  <button className='A-Semi-Bold sliderButton'  >CONTACT NOW
          </button></a>
        </div>
        </div>
      </div>
    </div>
   </div>
    </>
  )
}

export default Slider