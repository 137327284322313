import React, { useEffect, useState } from 'react'
import './copyright.css'


function Copyright() {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);



  return (
  <>
       <div class="container-fluid p-0">
        <div class="footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12 A-Light2">
                <p class="text-center A-Light2">
                  © Bu Qtair Restaurant.{year} All Right Reserved.
                </p>
                <p class="text-center A-Light2" >
                 <small><i>Powered By Febno Technologies</i></small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </>

  )
}

export default Copyright