import React from 'react'
import './delivery.css'
import { Link } from 'react-router-dom'

function Deliver() {

  return (
    <>
   <div class="container-fluid p-0" >
  <div class="delivery A-Thin">
      <div class="row">
        <div class="col-md-6 restuarent">
          <img src="images/restuarent.jpg" alt=""/>
        </div>
        <div class="col-md-6 contents">
          <div class="content text-center">
            <h4 class="w-100 mx-auto mt-4 mb-3">Discover <br />
              Where Moments Await</h4>
              <div>
                <h6 className='A-Thin'>
                  Bu Qtair Restaurant
                </h6>
                <ul className='mt-1'>
                  <li><p>Old 32B Street, Fishing Harbour 2 - Dubai</p></li>
                  <li><p>info@buqtairrestaurant.com
                  </p></li>
                  <li><p>+971 557052130</p></li>
                </ul>
              </div>
           <div class="buttons ms-5">
               <Link to={{ pathname: '/contactus', state: { fromViewButton: true } }}>
                <a className="button5" >view map</a>
                </Link>
                {/* <Link to={{ pathname: '/contactus', state: { fromViewButton: true } }}>
                <a class="button6 A-Heavy" href="/contactus" >book a table</a>
                </Link> */}
              </div>
            
              {/* <div className='deliveryDesc'>
                <img src="images/delivery.png" alt=""/>
                <h5 className='A-Light'>Fastest Delivery</h5>
                <p class="lines A-Light">At our Buqtair Restaurant, we take pride in delivering the freshest catch straight to your doorstep with lightning speed.</p>
              </div> */}
          </div>
        </div>
      </div>
    </div>
    </div>

  
    </>
  )
}

export default Deliver