import React from 'react'
import './slider3.css'



function Slider3() {
  return (
    <>
  <div class="container-fluid p-0" id='slidercontact' >
    <div class="banner" id='contactus' >
      <div class="container">
      <div class="row">
        <div class="texts">
          <div>
          <p className='A-Semi-Bold'>Contact Us</p>
          <h3 className='A-ULight'>Get in Touch, Let's Talk!</h3>
          <a href="/contactus" className='A-Semi-Bold '>  <button className='sliderButton' >CONTACT NOW
          </button></a>
        </div>
        </div>
      </div>
    </div>
   </div>
  </div>
    </>
  )
}

export default Slider3