import React from 'react'
import './Service.css'
function Service() {
  return (
    <>
     <div class="container-fluid p-0">
    <div class="service">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center section1">
            <div>
            <h5 className='A-Thin'>Why We Are The Best</h5>
            <h3 class="w-75 mx-auto A-Thin" style={{fontWeight:'500'}}>From Sea to Table, <br /> 
              Unforgettable Flavors Await!</h3>
            </div>
            <div>
              <p className='A-Regular'> Remember being a kid and being told, don’t judge a book by its cover? That age old saying was created for places like Bu Qtair. From humble beginnings as a tiny seafood shack, the now bona fide Dubai institution can now be found in a much larger space in Jumeirah Fishing Harbour, a stone’s throw away from the Burj Al Arab and Kite Beach. Head here with an open mind an appetite and make sure to leave any pretentiousness at the door. There are no bells and whistles here – and no menu either. Take your pick between whatever’s been caught fresh from the sea that day and prawns, priced by weight. The unassuming seafood joint is packed, both indoors and outdoors, and due to the queues of hungry diners our food takes a while to come but it’s without a doubt worth the wait. We opt for the sheri and a portion of prawns, which both come perfectly fried and pre-seasoned in fresh spices that pack a punch, along with a generous bowl of rice, an aromatic curry gravy and buttery parathas. It’s worth noting that it’s a card-free spot so make sure you visit with a pocket full of cash and get ready for your dirhams to take you far – you’ll get a filling meal for under Dhs150 for two here. For tourists and residents alike, Bu Qtair is a must-visit for unpretentious and undoubtedly scrummy super-fresh seafood.</p>
            </div>
            <div>
              <img src="images/tray (1).png" alt=""/>
              <h6 className='A-Light'>WHAT IS IT</h6>
              <p className='A-light'>A no-frills seafood joint</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Service