import React, { useEffect, useState } from "react";
import "./category.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Category() {
    const [data, setData] = useState("AED");
    useEffect(() => {
        AOS.init({

        });
        AOS.refresh({
          
        });
    }, []);
    return (
        <>
            <div className="container-fluid p-0">
                <div className="category A-Thin">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-center pb-5 A-Semi-Bold">CATEGORY II</p>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/pinapple.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0  juicename">
                                        <h5>
                                            Pineapple  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0  ">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>14</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/watermelon.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0  juicename">
                                        <h5>
                                            Watermelon  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>14</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/mango1.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                            Mango  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>16</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/passionfruit.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                            Passion Fruit  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>10</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/orange1.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>Orange Juice</h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>14</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/strawberry1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                            Strawberry  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>17</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/greenapple 1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                            Green Apple  
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>16</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/mint.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>Lemon Mint Ginger</h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>10</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/lemonade.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>Lemonade</h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>11</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/lemon mint.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                            Lemon Mint  
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>10</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/banana shake 1.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>Banana Juice</h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>10</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 juice" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img
                                            src="images/strawberry shake.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>
                                           Pomegranate  Juice
                                        </h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>16</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4 offset-md-4 juice mb-5" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-3">
                                        <img src="images/cocktail.png" alt="" />
                                    </div>
                                    <div className="col-md-6 p-0 juicename">
                                        <h5>Cocktail</h5>

                                    </div>
                                    <div className="col-md-6 p-0">
                                        <h4 className="A-Semi-Bold">
                                            {data} <span>12</span>
                                        </h4>
                                    </div>
                                </div>
                            </div> */}

                             <div className=" mb-5">

                             </div>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <a class="button3 " href="/menu">
                                       Explore More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Category;