
import React, { useEffect } from "react";
import "./Order.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Order() {
    useEffect(() => {
        AOS.init({
            // duration: 1000,
            // easing: "ease-out-cubic",
        });
        AOS.refresh({
            // duration: 1000,
            // easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>
            <div className="container-fluid p-0">
                <div className="order A-Thin">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-center A-Light">Our Menu</p>
                                <h3 className="text-center A-Light">
                                    Order for the table and spread the love
                                </h3>
                                <h4 className="text-center pb-5 A-Semi-Bold">
                                    CATEGORY I
                                </h4>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image1.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0 ">
                                        <h5 className="h5">Kofer fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image 2.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5"> Sheri Fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image 3.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5">Hamour Fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1000">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image 4.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5">Farsh Fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image 5.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5">Grilled seabass Fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 fish" data-aos="fade-down" data-aos-duration="1600">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            src="images/fish image 6.png"
                                            className="order-image"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5">Grilled Subream Fish</h5>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 offset-md-4 fish" data-aos="fade-down" data-aos-duration="1300">
                                <div className="juiceContent">
                                    <div className="col-md-7">
                                        <img
                                            className="order-image"
                                            src="images/fish image 7.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <h5 className="h5">Shrimp</h5>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Order;