import React from 'react'
import Delivery2 from '../../CONTACT-US/Deliver2/Delivery2'
import Slider5 from '../Slider5/Slider5'
import TopNav from '../../HOME/Navbar/Top-Nav/TopNav'
import MainNav from '../../HOME/Navbar/Main-nav/MainNav'
import Testimonials2 from '../../ABOUT-US/Testimonials/Testimonials2'
import Copyright from '../../HOME/Sections/CopyRight/Copyright'
import Dishes from '../Dishes/Dishes'
import Deliver3 from '../Deilvery3/Deliver3'
import Deliver from '../../HOME/Sections/Delivery/Deliver'



function KeyDishers() {
  return (
    <>
      {/* <TopNav /> */}
      <MainNav />
      <Slider5 />
      <Dishes/>
      <Testimonials2 />
      <Deliver/>
      <Copyright />

    </>
  )
}

export default KeyDishers