import React from 'react'
import './Dishes.css'

function Dishes() {
  return (
   <>
    <div class="container-fluid p-0">
    <div class="key-dishes A-Light">
     <div class="container">
      <div class="row">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-6">
              <div> <img src="images/kofer.png" alt=""/>
              </div>
             <div>
              <h3>Fried Kofer Fish</h3>
              <p>Fried Kofer Fish is a delectable seafood delight, prized for its crispy exterior and tender, flaky interior. Sourced from the freshest catches, the Kofer fish is carefully seasoned with a blend of traditional spices that highlight its natural flavors. Each fillet is lightly battered and deep-fried to golden perfection, ensuring a satisfying crunch with every bite.</p>
             </div>
            </div>
            <div class="col-md-6">
              <div><img src="images/sheri.png" alt=""/></div>
              <div>
                <h3>Fried Sheri Fish</h3>
                <p>Fried Sheri Fish is a popular dish that captures the essence of traditional seafood cuisine. Sheri, known for its mild, sweet flavor and firm texture, makes for an excellent choice for frying. Each fillet is marinated in a blend of aromatic spices and herbs that complement its natural taste, then coated in a light batter that turns wonderfully crispy when fried. The golden, crunchy exterior pairs exquisitely with the moist, succulent flesh of the fish, creating a delightful contrast in textures.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div><img src="images/hamour.png" alt=""/></div>
              <div>
                <h3>Fried Hamour Fish</h3>
                <p>Fried Hamour Fish is a beloved culinary treat, particularly favored for its delicate yet meaty texture. Hamour, a type of grouper found abundantly in Middle Eastern seas, is cherished for its rich, buttery flavor, making it an ideal candidate for frying.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div><img src="images/farsh.png" alt=""/></div>
              <div>
                <h3>Fried Farsh Fish</h3>
                <p>Fried Farsh Fish, often celebrated for its luxurious texture and delicate flavor, is a culinary favorite among seafood lovers. This dish features the Farsh fish, also known as emperor fish, which is highly prized for its succulent meat and versatility in various culinary preparations.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div> <img src="images/seabass.png" alt=""/></div>
             <div>
              <h3>Grilled Seabass Fish</h3>
              <p>Grilled Seabass is a sophisticated and popular dish celebrated for its light, buttery texture and subtle, delicate flavor. This dish utilizes the seabass, a fish favored in many cuisines for its flaky, tender flesh that perfectly absorbs flavors from marinades and seasonings.</p>
             </div>
            </div>
            <div class="col-md-6">
              <div><img src="images/sebass2.png" alt=""/></div>
              <div>
                <h3>Grilled Seabass Fish</h3>
                <p>Grilled Seabass is a sophisticated and popular dish celebrated for its light, buttery texture and subtle, delicate flavor. This dish utilizes the seabass, a fish favored in many cuisines for its flaky, tender flesh that perfectly absorbs flavors from marinades and seasonings.</p>
              </div>
            </div>
            <div class="col-md-6 mx-auto">
              <div><img src="images/shrimp.png" alt=""/></div>
              <div>
                <h3>Fried Shrimp</h3>
                <p>Fried Shrimp is a universally beloved dish, known for its crisp exterior and juicy, tender interior. This dish takes the naturally sweet flavor of shrimp and elevates it through a delicious, crunchy coating that's both satisfying to bite into and a delight to taste.</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
     </div> 
    </div>
  </div>
   </>
  )
}

export default Dishes