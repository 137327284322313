import React from 'react';
import './home.css';
import TopNav from '../Navbar/Top-Nav/TopNav';
import MainNav from '../Navbar/Main-nav/MainNav';
import Slider from '../Sections/slider/Slider';
import Menu from '../Sections/Menu/Menu';
import About from '../Sections/About/About';
import Order from '../Sections/Order/Order';
import Testimonials from '../Sections/testimonial/Testimonials';
import Category from '../Sections/category/Category';
import Gallery from '../Sections/gallery/Gallery';
import Deliver from '../Sections/Delivery/Deliver';
import Copyright from '../Sections/CopyRight/Copyright';


function Home() {

  return (
    <>
   
      {/* <TopNav /> */}
      <MainNav />
      <Slider />
      <Menu />
      <About />
      <Order />
      <Category />
      <Testimonials/>
      <Gallery />
      <Deliver />
      <Copyright />
    </>
  );
}

export default Home;
