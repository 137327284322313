import React from 'react'
import './slider6.css'


function Slider6() {
  return (
    <>
     <div class="container-fluid p-0" id='slidermenu'>
    <div class="banner"  id='menu' >
      <div class="container">
      <div class="row">
        <div class="texts">
          <div>
          <p className='A-Semi-Bold'>menu</p>
          <h3 className='A-ULight'>Dive into Delicious:<br/> 
            Explore the Ocean's Bounty!</h3>
            <a href="/contactus">  <button className='A-Semi-Bold sliderButton' >CONTACT NOW
          </button></a>
        </div>
        </div>
      </div>
    </div>
   </div>
  </div>
    </>
  )
}

export default Slider6