import React from 'react'
import TopNav from '../../../COMPONENTS/HOME/Navbar/Top-Nav/TopNav'
import MainNav from '../../../COMPONENTS/HOME/Navbar/Main-nav/MainNav'
import Slider2 from '../../../COMPONENTS/ABOUT-US/Slider2/Slider2'
import Service from '../../../COMPONENTS/ABOUT-US/Service/Service'
import Service2 from '../../../COMPONENTS/ABOUT-US/Service2/Service2'
import Testimonials2 from '../../../COMPONENTS/ABOUT-US/Testimonials/Testimonials2'
import Discover2 from '../Discover2/Discover2'
import Copyright from '../../../COMPONENTS/HOME/Sections/CopyRight/Copyright'
import Deliver from '../../HOME/Sections/Delivery/Deliver'

function AboutView() {
  return (
    <>
      <div>
        {/* <TopNav /> */}
        <MainNav />
        <Slider2 />
        <Service />
        <Service2 />
       <Testimonials2/>
       <Deliver/>
       <Copyright/>
      </div>
    </>
  )
}

export default AboutView