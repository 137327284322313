import React, { useEffect } from 'react'
import TopNav from '../../../COMPONENTS/HOME/Navbar/Top-Nav/TopNav'
import MainNav from '../../../COMPONENTS/HOME/Navbar/Main-nav/MainNav'
import Slider3 from '../Slider3/Slider3'
import Copyright from '../../../COMPONENTS/HOME/Sections/CopyRight/Copyright'
import Contactsection from '../ContactSection/ContactSection'
import Time from '../Time/Time'
import Delivery2 from '../Deliver2/Delivery2'
import Deliver from '../../HOME/Sections/Delivery/Deliver'



function ContactView() {

  return (
    <>
   <MainNav/>
    <Slider3/>
   <Deliver/>
    <Contactsection/>
    <Time/>
    <Copyright/>
    </>
  )
}

export default ContactView