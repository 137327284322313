import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './COMPONENTS/HOME/Home/Home';
import AboutView from './COMPONENTS/ABOUT-US/About-view/AboutView';
import GalleryView from './COMPONENTS/Gallery/GalleryView/GalleryView';
import ContactView from './COMPONENTS/CONTACT-US/ContactView/ContactView';
import KeyDishers from './COMPONENTS/KeyDishers/KeyDisherView/KeyDishers';
import MenuView from './COMPONENTS/MENU/MenuView/MenuView';
import LoadingScreen from './COMPONENTS/Loader/LoaderScreen';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };

    const handleEnd = () => {
      setLoading(false);
    };

    const startLoading = () => {
      handleStart();
    };

    const endLoading = () => {
      handleEnd();
    };

    window.addEventListener('beforeunload', startLoading);
    window.addEventListener('load', endLoading);

    return () => {
      window.removeEventListener('beforeunload', startLoading);
      window.removeEventListener('load', endLoading);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/aboutus' element={<AboutView />} />
            <Route path='/gallery' element={<GalleryView />} />
            <Route path='/Keydishes' element={<KeyDishers />} />
            <Route path='/contactus' element={<ContactView />} />
            <Route path='/contactus#map' element={<ContactView />} />
            <Route path='/menu' element={<MenuView />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;