import React from 'react'
import TopNav from '../../HOME/Navbar/Top-Nav/TopNav'
import MainNav from '../../HOME/Navbar/Main-nav/MainNav'
import Slider6 from '../Slider6/Slider6'
import Seasonal from '../Seasonal/Seasonal'
import Juices from '../Juice/Juices'
import Delivery2 from '../../CONTACT-US/Deliver2/Delivery2'
import Copyright from '../../../COMPONENTS/HOME/Sections/CopyRight/Copyright'
import Others from '../Others/Others'
import Deliver3 from '../../KeyDishers/Deilvery3/Deliver3'
import Soups from '../Soups/Soups'
import Deliver from '../../HOME/Sections/Delivery/Deliver'

function MenuView() {
  return (
   <>
     {/* <TopNav/> */}
    <MainNav/>
    <Slider6/>
    <Seasonal/>
    <Juices/>
    <Soups/>
    <Others/>
  <Deliver/>
    <Copyright/>
   </>
  )
}

export default MenuView