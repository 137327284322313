import React from 'react'
import './Juices.css'

function Juices() {
  return (
   <>
   <div class="container-fluid p-0">
  <div class="heading1">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center A-ULight " style={{fontSize:'58px', color:'white'}} >
          <img src="images/juice.png" className='ms-4 juiceimage' alt=""/>
          <h3 >Beverages</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid p-0">
      <div class="juices A-Light">
        <div class="container juice-content">
          <div class="row">  
          <div class="col-md-12  text-center" >
            <h3 style={{fontSize:"36px", color:'gray'}}>Juices</h3>
            </div> 
            <div class="col-md-6">
              <ul >
                <li>
                  <div class="item">
                    <span><img src="images/watermelon (1).png" alt=""/></span>
                    <h5 class="name">Watermelon</h5>
                    <span class="line"></span>
                    <p>14 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/mango.png" alt=""/></span>
                    <h5>Mango</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/pomegranate.png" alt=""/></span>
                    <h5>Pomegranate</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/orange.png" alt=""/></span>
                    <h5>Orange</h5>
                    <span class="line"></span>
                    <p>14 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/pineapple.png" alt=""/></span>
                    <h5>Pineapple</h5>
                    <span class="line"></span>
                    <p>14 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/banana (1).png" alt=""/></span>
                    <h5>Banana</h5>
                    <span class="line"></span>
                    <p>10 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/lemon (1).png" alt=""/></span>
                    <h5>Limeade</h5>
                    <span class="line"></span>
                    <p>8 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/strawberry (1).png" alt=""/></span>
                    <h5>Strawberry</h5>
                    <span class="line"></span>
                    <p>17 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/lemon (2).png" alt=""/></span>
                    <h5>Lemon Mint</h5>
                    <span class="line"></span>
                    <p>10 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/layer 90.png" alt=""/></span>
                    <h5>Mixed Berry</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>
                
              </ul>
            </div>
            
            <div class="col-md-6 text-center">

              <ul>
                <li>
                  <div class="item">
                    <span><img src="images/lemon (3).png" alt=""/></span>
                    <h5>Lemon Ginger</h5>
                    <span class="line"></span>
                    <p>10 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/lemon (2).png" alt=""/></span>
                    <h5>Lemon Pineapple</h5>
                    <span class="line"></span>
                    <p>12 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/grapes.png" alt=""/></span>
                    <h5>Grape</h5>
                    <span class="line"></span>
                    <p>15 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/lemon.png" alt=""/></span>
                    <h5>Lemon Mint Ginger</h5>
                    <span class="line"></span>
                    <p>10 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/banana.png" alt=""/></span>
                    <h5>Banana Strawberry</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/mango.png" alt=""/></span>
                    <h5>Lassi Mango</h5>
                    <span class="line"></span>
                    <p>14 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/banana (2).png" alt=""/></span>
                    <h5>Lassi Mango Banana</h5>
                    <span class="line"></span>
                    <p>15 DHS</p>
                  </div>
                </li>

                <li><div class="item">
                  <span><img src="images/banana (1).png" alt=""/></span>
                  <h5>Lassi Banana</h5>
                  <span class="line"></span>
                  <p>13 DHS</p>
                </div>

               
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/strawberry (1).png" alt=""/></span>
                    <h5>Lassi Mango Strawberry</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>
                <li>
                 
                  <div class="item">
                    <span><img src="images/green-apple.png" alt=""/></span>
                    <h5>Green Apple</h5>
                    <span class="line"></span>
                    <p>16 DHS</p>
                  </div>
                </li>
                
              </ul>
            </div>

            <div class="col-md-12  text-center"  >
            <h3   style={{fontSize:"36px", color:'gray'}}>Soft drinks</h3>
            </div> 
            <div class="col-md-6">
              <ul >
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Cola</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Thumbs up</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Sprite</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Cola diet</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Cola Zero</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>
                
              </ul>
            </div>
            
            <div class="col-md-6 text-center">
              <ul>
                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Mountain dew</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/Layer-106.png" alt=""/></span>
                    <h5>Fanta orange</h5>
                    <span class="line"></span>
                    <p>3 DHS</p>
                  </div>
                </li>

                <li><div class="item">
                  <span><img src="images/Layer-106.png" alt=""/></span>
                  <h5>Fanta citrus</h5>
                  <span class="line"></span>
                  <p>3 DHS</p>
                </div>

               
                </li>

                <li>
                  <div class="item">
                    <span><img src="images/Layer-107-copy.png" alt=""/></span>
                    <h5>Arwa water 500ml</h5>
                    <span class="line"></span>
                    <p>1 DHS</p>
                  </div>
                </li>
                <li>
                 
                  <div class="item">
                    <span><img src="images/Layer-107-copy.png" alt=""/></span>
                    <h5>Arwa water 1.5 ltr</h5>
                    <span class="line"></span>
                    <p>2 DHS</p>
                  </div>
                </li>                
              </ul>
            </div>
          </div>
        </div>
      </div>
     </div>
   </>
  )
}

export default Juices