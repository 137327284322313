import React from 'react'
import './Others.css'


function Others() {
  return (
    <>

<div class="container-fluid p-0">
        <div class="heading2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center U-Light">
                <img src="images/others.png" alt="" />
                <h3 style={{ color: 'white' }}>Others</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid p-0">
        <div class="others A-Light">
          <div class="container other-content">
            <div class="row">
              <div class="col-md-6">
                <ul>
                  <li>
                    <div class="item2">
                    <span><img src="images/layer-102.png" alt=""/></span>
                      <h5>Porotta</h5>
                      <span class="line"></span>
                      <p>1.50 <span>DHS</span></p>
                    </div>
                  </li>
                  <li>
                    <div class="item2">
                    <span><img src="images/layer-103.png" alt=""/></span>
                      <h5>Rice 6</h5>
                      <span class="line"></span>
                      <p>6 <span>DHS</span></p>
                    </div>
                  </li>
                </ul>

              </div>
              <div class="col-md-6">
                <ul>
                  <li>
                    <div class="item2">
                    <span><img src="images/layer-104.png" alt=""/></span>
                      <h5>Arabic bread</h5>
                      <span class="line"></span>
                      <p>2.50 <span>DHS</span></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Others