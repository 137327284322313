import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './menu.css';

function Menu() {

  const CustomPrevButton = (props) => (
    <button {...props} className="custom-prev-button">
      <i className="fa fa-chevron-left"></i>
    </button>
  );

  const CustomNextButton = (props) => (
    <button {...props} className="custom-next-button">
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  return (
    <div className="container-fluid p-0">
      <div className="menu A-Thin">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">Our Highlights</p>
              <h3 className="text-center A-Semi-Bold">Special Flavors, Sweet Deals!</h3>
            </div>
            <div className="carousel-container">
              <Slider {...settings}>
                <div className='item'>
                  <img className="menu-image" src="images/fried-kofer-fish.png" alt="Fried Kofer Fish" />
                  <h3 className="text-center text"> Kofer Fish</h3>
                </div>
                <div>
                  <img className="menu-image" src="images/fried-sheri-fish.png" alt="Fried Sheri Fish" />
                  <h3 className="text-center text"> Sheri Fish</h3>
                </div>
                <div>
                  <img className="menu-image" src="images/fried-hamour-fish.png" alt="Fried Hamour Fish" />
                  <h3 className="text-center text"> Hamour Fish</h3>
                </div>
                <div>
                  <img className="menu-image" src="images/fried-farsh-fish.png" alt="Fried Farsh Fish" />
                  <h3 className="text-center text">Farsh Fish</h3>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
