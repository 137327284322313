import React, { useState, useRef } from 'react';
import './Service2.css';

function Service2() {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleVideo = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
      setVideoPlaying(true);
    } else {
      video.pause();
      setVideoPlaying(false);
    }
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="aboutus">
          <div className="container">
            <div className="row">
              <div className="col-md-12 section2 text-center">
                <div>
                  <h3 className='A-Light'>Why go</h3>
                  <p className='A-Thin'>Remember being a kid and being told, don’t judge a book by its cover? That age old saying was created for places like Bu Qtair. From humble beginnings as a tiny seafood shack, the now bona fide Dubai institution can now be found in a much larger space in Jumeirah Fishing Harbour, a stone’s throw away from the Burj Al Arab and Kite Beach. Head here with an open mind, an appetite and make sure to leave any pretentiousness at the door. There are no bells and whistles here – and no menu either. Take your pick between whatever’s been caught fresh from the sea that day and prawns, priced by weight. The unassuming seafood joint is packed, both indoors and outdoors, and due to the queues of hungry diners our food takes a while to come but it’s without a doubt worth the wait. We opt for the sheri and a portion of prawns, which both come perfectly fried and pre-seasoned in fresh spices that pack a punch, along with a generous bowl of rice, an aromatic curry gravy and buttery parathas. It’s worth noting that it’s a card-free spot so make sure you visit with a pocket full of cash and get ready for your dirhams to take you far – you’ll get a filling meal for under Dhs150 for two here. For tourists and residents alike, Bu Qtair is a must-visit for unpretentious and undoubtedly scrummy super-fresh seafood.</p>
                </div>
                <div className="video-container">
                  <video
                    ref={videoRef}
                    width="1200"
                    height="800"
                    autoPlay={videoPlaying}
                    loop
                    muted
                    onClick={toggleVideo}
                  >
                    <source src="/images/banner video.mp4" type="video/mp4" />
                  </video>
                  {!videoPlaying && (
                    <button className="play-button" onClick={toggleVideo}>
                      <p className='A-Light'>WATCH OUR <br /> STORY <br /> IN JUST 45 SECONDS </p>
                      <img src="/images/play button.png" style={{height:"50px", width:"50px"}} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service2;
