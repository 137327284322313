import React from 'react'
import TopNav from '../../../COMPONENTS/HOME/Navbar/Top-Nav/TopNav'
import MainNav from '../../../COMPONENTS/HOME/Navbar/Main-nav/MainNav'
import Slider4 from '../Slider4/Slider4'
import './GalleryView.css'
import Copyright from '../../../COMPONENTS/HOME/Sections/CopyRight/Copyright'
import Discover2 from '../../ABOUT-US/Discover2/Discover2'
import Testimonials2 from '../../ABOUT-US/Testimonials/Testimonials2'
import Gallery2 from '../Gallery2/Gallery2'
import Deliver from '../../HOME/Sections/Delivery/Deliver'

function GalleryView() {
  return (
   <>
   <div>
     {/* <TopNav /> */}
    <MainNav />
    <Slider4/>
     <Gallery2/>
    <Testimonials2/>
  <Deliver/>
   <Copyright/>
   </div>
   </>
  )
}

export default GalleryView