import React from 'react';
import Lottie from 'lottie-react';

function LoadingScreen() {
  return (
    <div className="loading-screen" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', background: '#fff' }}>
      <div style={{ width: '300px', height: '300px' }}>
        <Lottie
          path="https://lottie.host/0ec7791e-a9f4-4d3d-8a6f-caf0c91aa79b/Eb66wSShBu.json"
          background="#fffbfb"
          speed={1}
          loop
          autoplay
          direction={1}
          mode="normal"
        />
      </div>
    </div>
  );
}

export default LoadingScreen;
