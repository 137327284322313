import React, { useEffect, useState } from 'react';
import './Contactsection.css';
import { useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';


function Contactsection() {


   
  
  // const location = useLocation();


  // useEffect(() => {
  //   if (location.state && location.state.fromViewButton) {
  //     document.getElementById('map').scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [location.state]);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

  
    // Validation
    let errors = {};
    let isValid = true;
  
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      errors.name = 'Name must contain only alphabets';
      isValid = false;
    }
    
  
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      isValid = false;
    }
  
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
      isValid = false;
    }
    setFormErrors(errors);
    if (isValid) { 
      Swal.showLoading();
      emailjs.send('service_rrb1r77', 'template_v9r4zx6', formData, 'cXLOyxkD-mYXIipRU')
        .then((response) => {  

          Swal.fire({
            title: '<span class="success-icon"></span>',
            html: 'Email sent successfully!',
            icon: 'success',
            customClass: {
              title: 'success-title', 
            },
          });

              setFormData({
                name: '',
                email: '',
                message: ''
              });
          
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
            setFormData({
              name: '',
              email: '',
              message: ''
            });
            setFormErrors({});
            Swal.close(); 
        });
    }
  };
  
  

  return (
    <div id="contact" className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 message A-Semi-Bold" >
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="name">First name</label><br />
                  <input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} placeholder="Name" required />
                  {formErrors.name && <span className="error" style={{ color: 'red' }}>{formErrors.name}</span>}
                </div>
                <div className="col-md-12">
                  <label htmlFor="email">Your Email</label><br />
                  <input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                  {formErrors.email && <span className="error" style={{ color: 'red' }}>{formErrors.email}</span>}
                </div>
                <div className="col-md-12">
                  <label htmlFor="message">Your Message</label><br />
                  <textarea name="message" id="message" value={formData.message} onChange={handleInputChange} placeholder="Message" cols="30" rows="10" required></textarea>
                  {formErrors.message && <span className="error" style={{ color: 'red' }}>{formErrors.message}</span>}
                </div>
                <div className="col-md-12">
                  <button className="button9" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-6 map" >
            <iframe
              title="Restaurant Location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7223.073427538658!2d55.19390360437299!3d25.1513491789203!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6a43c0e2b695%3A0xca1bbe502ba6cf62!2sBu%20Qtair%20Restaurant!5e0!3m2!1sen!2sin!4v1715227959947!5m2!1sen!2sin"
              className='mapiframe'
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactsection;
