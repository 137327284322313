import React from 'react';
import './Slider5.css'




function Slider5() {
  return (
    <div className="container-fluid p-0" id='sliderkeyshes' >
      <div className="banner" id='keydishes'>
        <div className="container">
          <div className="row">
            <div className="texts">
              <div>
                <p className='A-Semi-Bold'>our special dishes</p>
                <h3 className='A-ULight'>Dive Into Distinction<br/>with Every Dish!</h3>
                <a href="/contactus" className='A-Semi-Bold '><button className='sliderButton' >CONTACT NOW
               </button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider5;
