import React from 'react'
import './Time.css'
function Time() {
  return (
    <>
    <div class="container-fluid p-0">
        <div class="time">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul>
                  <li>
                    <h5 className='A-Heavy'>opening times</h5>
                  </li>
                  <li className='A-Semi-Bold'>
                    <p>Monday – Friday</p>
                    <p>7am – 6pm</p>
                  </li>
                  <li className='A-Semi-Bold'>
                    <p>Saturday/Sunday</p>
                    <p>8am – 6pm</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default Time