import React, { useEffect, useState } from "react";
import './Gallery2.css'
import AOS from "aos";
import "aos/dist/aos.css";
import "lightbox2/dist/css/lightbox.min.css";
import lightbox from "lightbox2";


function Gallery2() {
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        AOS.init({});
        AOS.refresh({});
    }, []);

    useEffect(() => {
        lightbox.option({
            'resizeDuration': 200,
            'wrapAround': true,
            'alwaysShowNavOnTouchDevices': true,
            'fadeDuration': 300,
            'imageFadeDuration': 300,
        }); 
    }, []);


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    return (
        <>
            <div class="container-fluid p-0">
                <div class="gallery ">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <p className='A-Thin'>Gallery</p>
                                <h3 className='A-Light'>Every Click Tells a Story.</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                            <a href="images/galleryimage1.jpg" data-lightbox="mygallery" >
                                    <img src="images/galleryimage1.jpg" alt="Gallery Image 1"  loading="lazy" />
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                            <a href="images/galleryimage2.jpg" data-lightbox="mygallery" >
                                    <img src="images/galleryimage2.jpg" alt="Gallery Image 2"  loading="lazy" />
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                            <a href="images/galleryimage3.jpg" data-lightbox="mygallery" >
                                    <img src="images/galleryimage3.jpg" alt="Gallery Image 3"   loading="lazy"/>
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                            <a href="images/galleryimage4.jpg" data-lightbox="mygallery" >
                                    <img src="images/galleryimage4.jpg" alt="Gallery Image 4"   loading="lazy"/>
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                            <a href="images/galleryimage5.jpg" data-lightbox="mygallery" >
                                    <img src="images/galleryimage5.jpg" alt="Gallery Image 5"   loading="lazy"/>
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                            <a href="images/galleryimage6.jpg" data-lightbox="mygallery">
                                    <img src="images/galleryimage6.jpg" alt="Gallery Image 6"  loading="lazy" />
                                </a> {/* Wrapping image in a link with Lightbox attributes */}
                            </div>
                        </div>
                        {showMore && (
                            <>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/80.jpg" data-lightbox="mygallery" >
                                            <img src="images/80.jpg" alt="Gallery Image 80"   loading="lazy"/>
                                        </a> {/* Wrapping image in a link with Lightbox attributes */}
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/81.jpg" data-lightbox="mygallery" >
                                            <img src="images/81.jpg" alt="Gallery Image 81"   loading="lazy"/>
                                        </a> {/* Wrapping image in a link with Lightbox attributes */}
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/82.jpg" data-lightbox="mygallery">
                                            <img src="images/82.jpg" alt="Gallery Image 82"  loading="lazy" />
                                        </a> {/* Wrapping image in a link with Lightbox attributes */}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/83.jpg" data-lightbox="mygallery" >
                                            <img src="images/83.jpg" alt="Gallery Image 83"  loading="lazy" />
                                        </a> {/* Wrapping image in a link with Lightbox attributes */}
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                        <img src="images/90.jpg" alt=""  loading="lazy" />
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/92.jpg" data-lightbox="mygallery" >
                                            <img src="images/92.jpg" alt="Gallery Image 92"  loading="lazy" />
                                        </a> {/* Wrapping image in a link with Lightbox attributes */}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/93.jpg" data-lightbox="mygallery" >
                                        <img src="images/93.jpg" alt="" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/94.jpg" data-lightbox="mygallery" >
                                        <img src="images/94.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/95.jpg" data-lightbox="mygallery" >
                                        <img src="images/95.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/96.jpg" data-lightbox="mygallery" >
                                        <img src="images/96.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/97.jpg" data-lightbox="mygallery" >
                                        <img src="images/97.jpg" alt=""   loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/98.jpg" data-lightbox="mygallery" >
                                        <img src="images/98.jpg" alt=""  loading="lazy"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/99.jpg" data-lightbox="mygallery" >
                                        <img src="images/99.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/100.jpg" data-lightbox="mygallery" >
                                        <img src="images/100.jpg" alt=""  loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/101.jpg" data-lightbox="mygallery" >
                                        <img src="images/101.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/103.jpg" data-lightbox="mygallery" >
                                        <img src="images/103.jpg" alt=""   loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/AOP05370-Recovered.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05370-Recovered.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/AOP05370.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05370.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/AOP05371.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05371.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/AOP05373.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05374-Recovered.jpg" alt=""   loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/AOP05375.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05375.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                                    <a href="images/AOP05376.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05376.jpg" alt=""  loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                                    <a href="images/AOP05377.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05377.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                    <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                                    <a href="images/AOP05379.jpg" data-lightbox="mygallery" >
                                        <img src="images/AOP05379.jpg" alt=""  loading="lazy" />
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="col-md-12 text-center">
                            {!showMore && (
                                <button className="button4 A-Regular" onClick={toggleShowMore}>
                                    Explore More
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Gallery2