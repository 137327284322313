import React from 'react'

function Soups() {
  return (
    <>
<div class="container-fluid p-0">
        <div class="heading2">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center U-Light">
                <img src="images/soup.png" alt="" />
                <h3 style={{  color: 'white' }}>Soups</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid p-0">
        <div class="others A-Light">
          <div class="container other-content">
            <div class="row">
              <div class="col-md-6">
                <ul>
                  <li>
                    <div class="item2">
                    <span><img src="images/veg soup.png" alt=""/></span>
                      <h5>Seafood Soup</h5>
                      <span class="line"></span>
                      <p>21 DHS</p>
                    </div>
                  </li>
                  {/* <li>
                    <div class="item2">
                      <h5>Rice 6</h5>
                      <span class="line"></span>
                      <p>6 DHS</p>
                    </div>
                  </li> */}
                </ul>

              </div>
              <div class="col-md-6">
                <ul>
                  <li>
                    <div class="item2">
                    <span><img src="images/veg soup.png" alt=""/></span>
                      <h5>Vegetable Soup</h5>
                      <span class="line"></span>
                      <p>18 DHS</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Soups