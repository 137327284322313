import React, { useEffect } from "react";
import './gallery.css'
import AOS from "aos";
import "aos/dist/aos.css";
function Gallery() {
  useEffect(() => {
    AOS.init({
        // duration: 1000,
        // easing: "ease-out-cubic",
    });
    AOS.refresh({
        // duration: 1000,
        // easing: "ease-out-cubic",
    });
}, []);
  return (
    <>
<div class="container-fluid p-0">
        <div class="gallery ">
          <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <p className='A-Thin' style={{color:'#a9b283'}}>Gallery</p>
              <h3 className='A-Light mt-2'>Every Click Tells a Story.</h3>
            </div>
            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
              <img src="images/galleryimage1.jpg" alt=""/>
            </div>
            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
              <img src="images/galleryimage2.jpg" alt=""/>
            </div>
            <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
              <img src="images/galleryimage3.jpg" alt=""/>
            </div>
            </div>
            <div class="row">
              <div class="col-md-4" data-aos="fade-down" data-aos-duration="1000">
                <img src="images/galleryimage4.jpg" alt=""/>
              </div>
              <div class="col-md-4" data-aos="fade-down" data-aos-duration="1300">
                <img src="images/galleryimage5.jpg" alt=""/>
              </div>
              <div class="col-md-4" data-aos="fade-down" data-aos-duration="1600">
                <img src="images/galleryimage6.jpg" alt=""/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <a class="button4 A-Regular" href="/gallery">Explore More</a>
              </div>
            </div>

          </div>
        </div>
      
      
      </div>


    </>
  )
}

export default Gallery