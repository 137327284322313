import React from 'react'
import './about.css'
function About() {

  
  return (
  <>
     <div className="container-fluid p-0" >
    <div className="about">
      <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
          <img src="images/seafood.png" alt=""/>
          <h3 className='A-Heavy' >Set Sail for Sensational Seafood!</h3>
          <p className="text-center A-Regular">Welcome to Bu Qtair, where the spirit of the sea meets the delight of dining. We invite you to savor the freshest catches, from succulent seafood platters to gourmet fish dishes, all served in a warm, nautical ambiance.</p>
          <a className="button2" href="/aboutus">Explore More</a>
        </div>
      </div>
    </div>
   </div>
  </div>
</div>
  </>
  )
}

export default About