import React from 'react';
import './Main-nav.css';

function MainNav() {
  return (
    <>
   
 <div class="container-fluid p-0 "  >
    <div class="header" id="custom-navbar">
        <div class="row">
          <nav class="navbar navbar-expand-md " id='navbar'>
            <div class="container-fluid">
              <a class="navbar-brand d-md-none" href="/">
                <img src="images/logo.png" alt=""/>
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon icon-white"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mx-auto justify-content-end mp-0 " >
                  <li class="nav-item">
                    <a class="nav-link A-Light " aria-current="page" href="/">HOME</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link A-Light" href="/aboutus">ABOUT</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link A-Light" href="/menu">MENU</a>
                  </li>
                  </ul>
                  <div className='justify-content-center '>
                  <a class="navbar-brand d-none d-md-block " href="/" >
                    <img src="images/logo copy svg.svg" alt=""/>
                  </a>
                  </div>
                  <ul class="navbar-nav mx-auto justify-content-start">
                  <li class="nav-item">
                    <a class="nav-link A-Light" href="/Keydishes">KEY DISHES</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link A-Light"  href='/gallery'>GALLERY</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link A-Light" href='/contactus'>CONTACT</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>     
        </div>
    </div>
 </div> 
    </>
  );
}

export default MainNav;
