import React, { useEffect } from 'react';
import 'swiper/swiper-bundle.min.css'; 
import Swiper from 'swiper/swiper-bundle.min.js'; 
import './testimonials2.css';

const Testimonials2 = () => {
    useEffect(() => {
        const swiper = new Swiper('.mySwiper', {
          cssMode: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
          },
          mousewheel: true,
          keyboard: true,
        });
      }, []);

  return (
    <>
       <div className="container-fluid p-0">
      <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
            <p className='A-Light mb-1' style={{color:" #a9b283"}}>Testimonials</p>
              <h3 className='A-Semi-Bold'>Where Every Bite Tells a Story</h3>
            </div>
            <div className="swiper mySwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div>
                    <p className="text w-75 mx-auto A-Heavy">
                      This is the place to go if you're looking for the freshest seafood. It was definitely worth waiting for a table, because the fried fish and prawns were amazing. In combination with rice and bread, it makes a delicious meal. Despite being tiny and simple, it's still better than all the fancy fish restaurants in town. Highly recommended!
                    </p>
                  </div>
                  <div className="user text-center p-5 A-Semi-Bold ">
                    <h6 className=''>Muhammed Rizwan</h6>
                    <p>Dubai, UAE</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <p className="text w-75 mx-auto A-Heavy">
                      This is the place to go if you're looking for the freshest seafood. It was definitely worth waiting for a table, because the fried fish and prawns were amazing. In combination with rice and bread, it makes a delicious meal. Despite being tiny and simple, it's still better than all the fancy fish restaurants in town. Highly recommended!
                    </p>
                  </div>
                  <div className="user text-center p-5  A-Semi-Bold">
                    <h6>Muhammed Rizwan</h6>
                    <p>Dubai, UAE</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <p className="text w-75 mx-auto A-Heavy">
                      This is the place to go if you're looking for the freshest seafood. It was definitely worth waiting for a table, because the fried fish and prawns were amazing. In combination with rice and bread, it makes a delicious meal. Despite being tiny and simple, it's still better than all the fancy fish restaurants in town. Highly recommended!
                    </p>
                  </div>
                  <div className="user text-center p-5  A-Semi-Bold">
                    <h6>Muhammed Rizwan</h6>
                    <p>Dubai, UAE</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <p className="text w-75 mx-auto A-Heavy">
                      This is the place to go if you're looking for the freshest seafood. It was definitely worth waiting for a table, because the fried fish and prawns were amazing. In combination with rice and bread, it makes a delicious meal. Despite being tiny and simple, it's still better than all the fancy fish restaurants in town. Highly recommended!
                    </p>
                  </div>
                  <div className="user text-center p-5  A-Semi-Bold">
                    <h6>Muhammed Rizwan</h6>
                    <p>Dubai, UAE</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <p className="text w-75 mx-auto A-Heavy">
                      This is the place to go if you're looking for the freshest seafood. It was definitely worth waiting for a table, because the fried fish and prawns were amazing. In combination with rice and bread, it makes a delicious meal. Despite being tiny and simple, it's still better than all the fancy fish restaurants in town. Highly recommended!
                    </p>
                  </div>
                  <div className="user text-center p-5  A-Semi-Bold">
                    <h6>Muhammed Rizwan</h6>
                    <p>Dubai, UAE</p>
                  </div>
                </div>
              </div>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Testimonials2;
