import React from 'react'
import './Slider4.css'


function Slider4() {
  return (
    <>
    <div class="container-fluid p-0"  id='slidergallery'>
    <div class="banner"  id='gallery' >
      <div class="container">
      <div class="row">
        <div class="texts">
          <div>
          <p className='A-Semi-Bold'>Photo gallery</p>
          <h3 className='A-ULight'>Explore Our World,<br/> 
            Frame by Frame</h3>
            <a href="/contactus" className='A-Semi-Bold'>  <button className='sliderButton' >CONTACT NOW
          </button></a>
        </div>
        </div>
      </div>
    </div>
   </div>
  </div>
    </>
  )
}

export default Slider4