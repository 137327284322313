import React from 'react'
import './Slider2.css'
// import styles from '../../KeyDishers/Slider5/Slider5.module.css';

function Slider2() {
  return (
 <>
<div class="container-fluid p-0 mb-0 mt-0" id='sliderbanner'>
    <div class="banner" id='banner2'>
      <div class="container">
      <div class="row">
        <div class="texts">
          <div>
          <p className='A-Light' style={{fontWeight:'600'}}>FAVOUR OF THE OCEAN</p>
          <h3 className='A-ULight Arabian' >Arabian Delight!</h3>
          <a href="/contactus" className='A-Semi-Bold '>  <button className='sliderButton' >CONTACT NOW
          </button></a>
          
        </div>
        </div>
      </div>
    </div>
   </div>
  </div>

 </>
    
  )
}

export default Slider2